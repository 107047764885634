

























































































































































































import { Component, Vue } from 'vue-property-decorator';
import { readAdminSessionsByState } from '@/store/admin/getters';
import {
  dispatchGetAdminSessions,
  dispatchSetSessionPublic,
} from '@/store/admin/actions';

@Component
export default class Sessions extends Vue {
  public loading = true;
  public search = '';
  public headers = [
    {
      text: this.mobile ? 'Status:' : 'Status',
      align: 'left',
      width: '50px',
      value: 'status',
      fixed: true,
    },
    {
      text: this.mobile ? 'User:' : 'User',
      sortable: true,
      value: 'owner',
      align: 'left',
      width: '200px',
      fixed: true,
    },
    {
      text: this.mobile ? 'Title:' : 'Title',
      sortable: true,
      value: 'title',
      align: 'left',
      width: '300px',
      fixed: true,
    },
    {
      text: this.mobile ? 'Created:' : 'Date created',
      align: 'left',
      value: 'created_date',
      width: '50px',
      fixed: true,
    },
    {
      text: this.mobile ? 'Started / Completed:' : '# Started / Completed',
      value: 'completes',
      align: 'left',
      width: '50px',
      fixed: true,
    },
  ];

  public closedHeaders = [
    ...this.headers,
    {
      text: 'Actions',
      align: 'left',
      value: 'actions',
      width: '50px',
      fixed: true,
    }
  ]

  public rowClick (item, row) {      
    row.select(true);
    this.$router.push({
      name: 'main-user-session-view',
      params: { id: item.id },
    });
  }

  public getFormattedDate(date) {
    return new Date(date).toLocaleDateString();
  }

  public getParticipantsStarted(item) {
    return item.p_started ? item.p_started : 0;
  }

  public getParticipantsFinished(item) {
    return item.p_finished ? item.p_finished : 0;
  }

  public async setPublic(id: string) {
    await dispatchSetSessionPublic(this.$store, { id });
  }

  public sessions(sessionState: string) {
    return readAdminSessionsByState(this.$store)(sessionState);
  }

  public async mounted() {
    await dispatchGetAdminSessions(this.$store);
    this.loading = false;
  }
  get mobile() {
    return this.$vuetify.breakpoint.name === 'xs';
  }
  get buttonSize() {
    return this.$vuetify.breakpoint.mobile ? { ['x-small']: true } : { ['small']: true };
  }
}
